import { Inject, Injectable } from '@angular/core';
import { AuthenticationDetails, CognitoUser, CognitoUserPool, CognitoUserSession, ICognitoUserPoolData } from 'amazon-cognito-identity-js';
import { from as observableFrom, Observable, ReplaySubject, Subject, of as observableOf } from 'rxjs';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import { UserData } from 'src/app/user/models/user-data';
import { AuthConfig } from '../configs/auth.config';
import { AUTH_CONFIG_TOKEN } from '../tokens';

@Injectable({
    providedIn: 'root'
})
export class CognitoService {
    private cognitoUserPool: CognitoUserPool | undefined;
    private cognitoUser: CognitoUser | undefined;

    private newSignInProcess = new Subject<void>();

    constructor(
        @Inject(AUTH_CONFIG_TOKEN) private authConfig: AuthConfig,
    ) {
        this.startupService();
    }

    public startupService(): void {
        var poolData: ICognitoUserPoolData = {
            UserPoolId: this.authConfig.userPoolId,
            ClientId: this.authConfig.clientId,
        };

        this.cognitoUserPool = new CognitoUserPool(poolData);
    }

    public getCurrentUser(): CognitoUser | undefined {
        var user = this.cognitoUserPool?.getCurrentUser() ?? undefined

        return user;
    }

    public isUserLoginValid(): Observable<boolean> {
        var user = this.getCurrentUser();

        if (user) {
            return this.getUserSession().pipe(
                switchMap((session) => {
                    if (session) {
                        return observableOf(session.isValid());
                    }
                    else {
                        return this.refreshSession();
                    }
                })
            );

        }

        return observableOf(false);
    }

    public getUserSession(): Observable<CognitoUserSession | undefined> {
        const subject = new ReplaySubject<CognitoUserSession | undefined>();

        var user = this.getCurrentUser();

        if (user) {
            user.getSession((err: Error | null, session: CognitoUserSession | undefined) => {
                if (err) {
                    subject.error(err);
                }

                subject.next(session);
            });
        }

        return subject.asObservable().pipe(take(1));
    }

    public getUserAttributes(): Observable<UserData | undefined> {
        return this.getUserSession().pipe(
            map((session) => {
                if (session) {
                    const token = session.getIdToken();

                    return new UserData({
                        username: token.payload['email'],
                        given: token.payload['given_name'],
                        family: token.payload['family_name'],
                        email: token.payload['email'],
                        userId: token.payload['sub'],
                        groups: token.payload['cognito:groups'] ?? []
                    });
                }

                return undefined;
            })
        );
    }

    public signIn(email: string, challengeCallbackSetup: () => void, challengeCallback: Observable<string | undefined>): Observable<boolean> {
        this.newSignInProcess.next();

        this.setupCognitoUser(email);

        const promise = new Promise<boolean>((resolve) => {
            let _authenticationCallback = {
                onSuccess: () => {
                    resolve(true);
                },
                onFailure: (error: any) => {
                    alert(error.message.replace('USERNAME', 'EMAIL') || JSON.stringify(error));
                    resolve(false);
                },
                customChallenge: () => {
                    challengeCallbackSetup();

                    challengeCallback.pipe(take(1)).subscribe((answer) => {
                        if (!answer) return;

                        this.cognitoUser!.sendCustomChallengeAnswer(answer, {
                            ..._authenticationCallback,
                            customChallenge: () => {
                                _authenticationCallback.customChallenge();

                                if (!this.isUserLoginValid()) {
                                    alert('Invalid code. Please try again.');
                                }
                            }
                        });
                    });
                },
            };

            let authenticationDetails = new AuthenticationDetails({
                Username: email,
            });

            this.cognitoUser!.initiateAuth(authenticationDetails, _authenticationCallback);
        });

        return observableFrom(promise).pipe(
            takeUntil(this.newSignInProcess),
            take(1),
        );
    }

    public getIdToken(): Observable<string | undefined> {
        return this.getUserSession().pipe(
            map((session) => {
                if (session) {
                    return session.getIdToken().getJwtToken();
                }

                return undefined;
            })
        );
    }

    public refreshSession(): Observable<boolean> {
        return this.getUserSession().pipe(
            switchMap((oldSession) => {
                const subject = new ReplaySubject<CognitoUserSession | undefined>(1);

                const user = this.getCurrentUser();

                if (user && oldSession) {
                    const token = oldSession.getRefreshToken();

                    user.refreshSession(token, () => {
                        this.getUserSession().pipe(
                            take(1)
                        ).subscribe((newSession) => {
                            subject.next(newSession);
                        });
                    });
                }

                else {
                    subject.next(undefined);
                }

                return subject.asObservable().pipe(take(1));
            }),
            map((session) => {
                return session?.isValid() ?? false;
            })
        );
    }

    public logout(): void {
        const user = this.getCurrentUser();

        if (user) user.signOut();
    }

    private setupCognitoUser(email: string): void {
        if (!this.cognitoUserPool) throw 'ERROR - Cognito User Pool not created';

        let userData = { Username: email, Pool: this.cognitoUserPool };

        this.cognitoUser = new CognitoUser(userData);
    }
}
