import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, of as observableOf, Observable, Subject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { CognitoService } from './cognito.service';
import { APP_BASE_URL } from 'src/app/shared/tokens';
import { UserService } from 'src/app/user/services/user.service';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    public get waitingVerification(): Observable<boolean> {
        return this._waitingVerification.asObservable();
    };

    private _waitingVerification = new BehaviorSubject<boolean>(false);

    private _answerSubject = new Subject<string | undefined>();

    private _redirectUri: string | undefined;

    constructor(
        private cognitoService: CognitoService,
        private userService: UserService,
        private router: Router,
        @Inject(APP_BASE_URL) private baseUrl: string,
        @Inject('Window') private window: Window
    ) { }

    public startAuth(): Observable<boolean> {
        this._redirectUri = !this.isRedirectURIHome(this.window.location.href) ? decodeURIComponent(this.window.location.href) : `${this.baseUrl}/home`;

        return this.validateUserLogin();
    }

    public signIn(email: string): Observable<boolean | undefined> {
        return this.cognitoService.signIn(
            email,
            () => {
                this._waitingVerification.next(true);
            },
            this._answerSubject
        ).pipe(
            switchMap((userSignedIn) => {
                if (userSignedIn) {
                    return this.validateUserLogin();
                }

                if (!userSignedIn && userSignedIn !== undefined) {
                    this.stopWaitingVerification();
                }

                return observableOf(userSignedIn);
            }),
            tap((userAuthenticated) => {
                if (userAuthenticated) {
                    this.redirectUser().then(() => this.stopWaitingVerification());
                }
            })
        );
    }

    public sendAuthCode(code: string): void {
        this._answerSubject.next(code);
    }

    public isSessionValid(): Observable<boolean> {
        return this.cognitoService.getUserSession().pipe(
            map((session) => {
                return session?.isValid() ?? false;
            })
        );
    }

    public cancelExistingSignInProcess(): void {
        this._answerSubject.next(undefined);
    }

    public refreshSession(): Observable<boolean> {
        return this.cognitoService.refreshSession();
    }

    public getIdToken(): Observable<string | undefined> {
        return this.cognitoService.getIdToken();
    }

    public stopWaitingVerification(): void {
        this._waitingVerification.next(false);
        this.cancelExistingSignInProcess();
    }

    public logout(): void {
        this.cognitoService.logout();

        this.userService.setCurrentUser(undefined);

        this.window.location.href = `${this.baseUrl}/login`;
    }

    public async redirectUser(): Promise<boolean> {
        if (!this._redirectUri) return Promise.resolve(false);

        const route = this.getRouteFromURI(this._redirectUri);

        return this.router.navigate([route]);
    }

    private validateUserLogin(): Observable<boolean> {
        return this.cognitoService.isUserLoginValid().pipe(
            switchMap((isValid) => {
                if (isValid) {
                    return this.cognitoService.refreshSession().pipe(
                        switchMap(() => {
                            return this.cognitoService.getUserAttributes();
                        }),
                        tap((userData) => {
                            if (userData) {
                                this.userService.setCurrentUser(userData);
                            }
                        }),
                        map((userData) => {
                            return userData !== undefined;
                        })
                    );
                }

                return observableOf(false);
            })
        );
    }

    private isRedirectURIHome(redirectURI: string): boolean {
        const route = this.getRouteFromURI(redirectURI);

        return route === '' || route === `/home` || route === `/login`;
    }

    private getRouteFromURI(redirectURI: string) {
        const substringURI = redirectURI.substring(this.baseUrl.length);
        return substringURI.replace(/[/]$/, '');
    }
}
