import { Component, ElementRef, OnInit } from '@angular/core';
import { AssetsService } from '../../services/assets.service';
import { BaseModalComponent } from 'src/app/shared/modal/components/base-modal/base-modal.component';
import { ModalService } from 'src/app/shared/modal/services/modal.service';

@Component({
    selector: 'app-thumbnail-selector-modal',
    templateUrl: './thumbnail-selector-modal.component.html',
    styleUrls: ['./thumbnail-selector-modal.component.scss']
})
export class ThumbnailSelectorModalComponent extends BaseModalComponent implements OnInit {
    public readonly PAGE_SIZE = 9;

    public thumbnailCategories: string[] = [];
    public thumbnails: Map<string, string[]> | undefined;
    public thumbnailsForCategory: string[] = [];
    public selectedThumbnailCategory: string | undefined;

    public initialIndex: number = -1;
    public selectedIndex: number = -1;
    public numPages: number = -1;
    public currentPage: number = 1;

    public thumbnailsForPage: string[] = [];
    public selectedThumbnail: string | undefined;

    public retrievingThumbnails: boolean = false;

    constructor(
        private assetsService: AssetsService,
        protected elementRef: ElementRef,
        protected override modalService: ModalService
    ) { 
        super(elementRef, modalService);
    }

    public override ngOnInit(): void {
        super.ngOnInit();
        
        this.retrievingThumbnails = true;

        this.assetsService.getThumbnails().subscribe((thumbnails) => {
            if (thumbnails) {
                this.thumbnails = thumbnails;
                this.thumbnailCategories = Array.from(thumbnails.keys());
                this.selectThumbnailCategory(this.thumbnailCategories[0]);

                this.initialIndex = 0;
            }

            this.retrievingThumbnails = false;
        });
    }

    public selectThumbnail(index: number): void {
        if (this.thumbnailsForCategory && index <= this.thumbnailsForCategory.length) {
            const thumbnail = this.thumbnailsForCategory[index];
            if (thumbnail) {
                this.selectedIndex = index;
                this.selectedThumbnail = thumbnail;
            }
        }
    }

    public selectThumbnailCategory(category: string): void {
        if (this.selectedThumbnailCategory === category)
            return;

        this.selectedThumbnailCategory = category;
        this.thumbnailsForCategory = this.thumbnails!.get(category)!;
        this.numPages = this.convertIndexToPage(this.thumbnailsForCategory.length - 1);
        this.selectedIndex = 0;
        this.updateThumbnailsForPage();
    }

    public changePage(newPage: number): void {
        this.currentPage = newPage;

        this.updateThumbnailsForPage();
    }

    public applyThumbnail(): void {
        this.closeModal(this.selectedThumbnail);
    }

    private convertIndexToPage(index: number): number {
        const page = Math.ceil((index + 1) / this.PAGE_SIZE);

        return page > 0 
            ? page 
            : 1;
    }

    private updateThumbnailsForPage(): void {
        const startIndex = this.currentPage === 1
            ? 0
            : ((this.currentPage - 1) * this.PAGE_SIZE);
        const endIndex = (this.currentPage * this.PAGE_SIZE) - 1;

        const thumbnails = this.thumbnailsForCategory.slice(startIndex, endIndex + 1);

        this.thumbnailsForPage = thumbnails;
    }
}